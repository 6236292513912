import * as React from "react";
import { graphql, Link } from "gatsby";
import slugify from "slugify";

import { ym_tracking_id } from "../../constants";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Search from "../components/search";
import { getAmcName, getCategoryName } from "../helpers/lookup";
import { capitalize } from "../helpers/capitalize";

const ParentFundBoxIcon = ({ link, text1, text2, name }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        if (typeof window !== "undefined" && window.ym) {
          window.ym(ym_tracking_id, "reachGoal", name);
        }
        return true;
      }}
    >
      <span className="inline-block w-20 h-20 px-1 py-2 m-1 text-center align-top bg-blue-100 border border-blue-500 rounded hover:bg-blue-200">
        <p className="font-serif text-3xl font-semibold text-blue-500">
          {text1}
        </p>
        <p className="text-xs text-blue-800">{text2}</p>
      </span>
    </a>
  );
};

const ParentFundSection = ({ data }) => {
  return (
    <div className="overflow-hidden bg-white border border-gray-300 rounded-md">
      <div className="px-5 py-1 text-gray-500 bg-gray-100 border-b border-gray-300">
        กองทุนหลัก
        <svg
          className="inline w-5 h-5 ml-2 text-green-400 align-text-top"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clipRule="evenodd"
          />
        </svg>
      </div>

      <div className="divide-y divide-gray-300">
        {data._mydata?.map((parentFund) => {
          return (
            <div key={parentFund.isin}>
              <div className="mx-5 my-3">
                <p className="mt-2 text-xl leading-snug">
                  <a
                    title={data._feeder_fund?.main_feeder_fund}
                    href={parentFund.link}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      if (typeof window !== "undefined" && window.ym) {
                        window.ym(ym_tracking_id, "reachGoal", "parent_title");
                      }
                      return true;
                    }}
                  >
                    {parentFund.name}
                  </a>
                </p>
                <p className="mt-1 text-sm text-gray-500">
                  <Link
                    to={`/global/${slugify(parentFund.amc_code)}`}
                    onClick={() => {
                      if (typeof window !== "undefined" && window.ym) {
                        window.ym(
                          ym_tracking_id,
                          "reachGoal",
                          "globalamc_title_link"
                        );
                      }
                      return true;
                    }}
                  >
                    {parentFund.amc_code}
                  </Link>
                  {parentFund.class && ` - Class ${parentFund.class}`}
                  {parentFund.currency && ` - ${parentFund.currency}`}
                </p>
                <div className="mt-2 text-center">
                  {parentFund.link && (
                    <ParentFundBoxIcon
                      name="parent"
                      link={parentFund.link}
                      text1={
                        <svg
                          className="mx-auto h-9 w-9"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                        </svg>
                      }
                      text2={parentFund.amc_code}
                    />
                  )}
                  {parentFund.bloomberg && (
                    <ParentFundBoxIcon
                      name="bloomberg"
                      link={parentFund.bloomberg}
                      text1="B"
                      text2="Bloomberg"
                    />
                  )}
                  {parentFund.ft && (
                    <ParentFundBoxIcon
                      name="ft"
                      link={parentFund.ft}
                      text1="F"
                      text2="Financial Times"
                    />
                  )}
                  {parentFund.investing && (
                    <ParentFundBoxIcon
                      name="investing"
                      link={parentFund.investing}
                      text1="I"
                      text2="Investing"
                    />
                  )}
                  {parentFund.tradingview && (
                    <ParentFundBoxIcon
                      name="tradingview"
                      link={parentFund.tradingview}
                      text1="T"
                      text2="TradingView"
                    />
                  )}
                  {parentFund.morningstar_uk && (
                    <ParentFundBoxIcon
                      name="morningstar_uk"
                      link={parentFund.morningstar_uk}
                      text1="M"
                      text2="Morningstar UK"
                    />
                  )}
                  {parentFund.morningstar_sg && (
                    <ParentFundBoxIcon
                      name="morningstar_sg"
                      link={parentFund.morningstar_sg}
                      text1="M"
                      text2="Morningstar SG"
                    />
                  )}
                  {parentFund.morningstar_us && (
                    <ParentFundBoxIcon
                      name="morningstar_us"
                      link={parentFund.morningstar_us}
                      text1="M"
                      text2="Morningstar US"
                    />
                  )}
                  {parentFund.morningstar_hk && (
                    <ParentFundBoxIcon
                      name="morningstar_hk"
                      link={parentFund.morningstar_hk}
                      text1="M"
                      text2="Morningstar HK"
                    />
                  )}
                  {parentFund.citywireselector && (
                    <ParentFundBoxIcon
                      name="citywireselector"
                      link={parentFund.citywireselector}
                      text1="C"
                      text2="Citywire Selector"
                    />
                  )}
                </div>
                <p
                  className="mt-1 text-sm text-gray-500"
                  dangerouslySetInnerHTML={{ __html: parentFund.note }}
                />
              </div>
            </div>
          );
        })}
        <div className="mt-10">
          <div className="mx-5 my-2">
            <p className="mt-2 text-sm text-gray-500">
              <svg
                className="inline w-5 h-5 mr-1 text-green-400 align-text-top"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              กองทุนนี้ มีข้อมูลที่ กองแม่.com รวบรวมเพิ่มเติม{" "}
              <br className="hidden sm:inline" />
              โดยอ้างอิงรายละเอียดจาก หนังสือชี้ชวน หรือ เว็บไซต์ของบลจ.ไทย
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const SecDataSection = ({ data }) => {
  return (
    <div className="overflow-hidden bg-white border border-gray-300 rounded-md">
      <div className="px-5 py-1 text-gray-500 bg-gray-100 border-b border-gray-300">
        กองทุนหลัก
        <svg
          className="inline w-5 h-5 ml-2 text-blue-400 align-text-top"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div className="mx-5 my-2">
        <p className="mt-2 mb-10 text-xl leading-snug">
          {data._feeder_fund?.main_feeder_fund}
        </p>
        <hr className="border-gray-300" />
        <p className="mt-2 text-sm text-gray-500">
          <svg
            className="inline w-5 h-5 mr-1 text-blue-400 align-text-top"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
              clipRule="evenodd"
            />
          </svg>
          กองทุนนี้ มีข้อมูลจาก กลต.
          <br></br>
          โดยกองแม่.com ยังไม่ได้รวบรวมข้อมูลเพิ่มเติม
        </p>
      </div>
    </div>
  );
};

const NoDataSection = () => {
  return (
    <div className="overflow-hidden bg-white border border-gray-300 rounded-md">
      <div className="px-5 py-1 text-gray-500 bg-gray-100 border-b border-gray-300">
        กองทุนหลัก
        <svg
          className="inline w-5 h-5 ml-2 text-gray-400 align-text-top"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div className="mx-5 my-2">
        <p className="mt-2 mb-10 text-lg leading-normal text-gray-700">
          ยังไม่มีข้อมูล
        </p>
        <hr className="border-gray-300" />
        <p className="mt-2 text-sm text-gray-500">
          <svg
            className="inline w-5 h-5 mr-1 text-gray-400 align-text-top"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
          กองทุนนี้ ยังไม่มีข้อมูล โดยอาจเป็นเพราะ เป็นกองทุนที่ บลจ.
          ไทยบริหารจัดการเอง เป็นกองทุนที่ กองแม่.com ยังไม่ได้รวบรวมข้อมูลมา
          หรือ เป็นกองทุนที่ไม่ได้ลงทุนในต่างประเทศ
        </p>
      </div>
    </div>
  );
};

const Top5Section = ({ data, className }) => {
  let period;
  if (Array.isArray(data._top5) && data._top5.length > 0) {
    const periodraw = data._top5[0].period;
    switch (periodraw.substring(4)) {
      case "03":
        period = `ไตรมาส 1 ปี ${periodraw.substring(0, 4)}`;
        break;
      case "06":
        period = `ไตรมาส 2 ปี ${periodraw.substring(0, 4)}`;
        break;
      case "09":
        period = `ไตรมาส 3 ปี ${periodraw.substring(0, 4)}`;
        break;
      case "12":
        period = `ไตรมาส 4 ปี ${periodraw.substring(0, 4)}`;
        break;
      default:
        break;
    }
  }

  return (
    <section className={className}>
      <div className="overflow-hidden bg-white border border-gray-300 rounded-md">
        <div className="px-5 py-1 text-gray-500 bg-gray-100 border-b border-gray-300">
          {data.proj_abbr_name} : Top 5 Holdings
        </div>
        <div className="mx-5 my-2">
          <div className="my-2 text-lg leading-normal text-gray-700">
            <ol className="pl-5 list-decimal">
              {data._top5.map((x, i) => {
                return (
                  <li className="text-gray-400" key={i}>
                    <span className="flex text-gray-500">
                      <span className="flex-auto">
                        {x.secur_name
                          ? capitalize(x.secur_name)
                          : x.secur_abbr_name}
                      </span>
                      <span className="flex-auto text-right">
                        {x.secur_invest_size}%
                      </span>
                    </span>
                  </li>
                );
              })}
            </ol>
          </div>
          <hr className="border-gray-300" />
          <p className="mt-2 text-sm text-gray-500">ข้อมูล {period}</p>
        </div>
      </div>
    </section>
  );
};

const FundInfoSection = ({ fundCode, data, className }) => {
  if (data._mydata?.length > 0) {
    return (
      <section className={className}>
        <Seo
          title={`${fundCode} ${data.proj_name_th}`}
          description={`ข้อมูลกองทุนหลัก (กองแม่ / ไส้ใน) ของ ${
            data.proj_name_th
          } ประเภท ${getCategoryName(
            data._compare?.fund_compare
          )} เช่น Bloomberg Morningstar Investing.com FT`}
        />
        <ParentFundSection data={data} />
      </section>
    );
  } else if (data._feeder_fund) {
    return (
      <section className={className}>
        <Seo
          title={`${fundCode} ${data.proj_name_th}`}
          description={`ข้อมูลกองทุนหลัก (กองแม่) ของ ${
            data.proj_name_th
          } ประเภท ${getCategoryName(data._compare?.fund_compare)}`}
        />
        <SecDataSection data={data} />
      </section>
    );
  } else {
    return (
      <section className={className}>
        <Seo
          title={`${fundCode} ${data.proj_name_th}`}
          description={`กองทุน ${data.proj_name_th} ประเภท ${getCategoryName(
            data._compare?.fund_compare
          )}`}
        />
        <NoDataSection data={data} />
      </section>
    );
  }
};

const FundInfoPage = (props) => {
  const data = props.data.fundinfoJson;
  const fundCode = props.pageContext.abbr;
  return (
    <Layout>
      <div className="container px-4 mx-auto max-w-screen-md">
        <section className="px-4 mt-4">
          <Search />
        </section>
        <section className="mt-1">
          <div className="px-4 pt-4">
            <h1 className="text-4xl text-gray-700">{fundCode}</h1>
            <h2 className="text-lg text-gray-500">{data.proj_name_th}</h2>
            <div className="mt-1 grid grid-cols-2">
              <div>
                <p className="text-sm text-gray-500">
                  {getCategoryName(data._compare?.fund_compare)}
                </p>
                <p className="text-sm text-gray-500">
                  {capitalize(getAmcName(data.unique_id))}
                </p>
              </div>
              <div className="relative text-right">
                <a
                  href={data._url?.url_factsheet}
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="absolute bottom-0 right-0 inline px-2 py-1 text-sm text-gray-500 align-bottom bg-gray-100 border border-gray-300 rounded-md">
                    <svg
                      className="inline w-5 h-5 text-gray-500 align-text-top"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                    หนังสือชี้ชวน
                  </p>
                </a>
              </div>
            </div>
          </div>
        </section>
        <FundInfoSection fundCode={fundCode} data={data} className="mt-4" />
        {data._top5 && <Top5Section data={data} className="mt-4" />}
      </div>
    </Layout>
  );
};

export default FundInfoPage;

export const query = graphql`
  query ($proj_id: String) {
    fundinfoJson(proj_id: { eq: $proj_id }) {
      proj_name_en
      proj_name_th
      proj_abbr_name
      unique_id
      _compare {
        fund_compare
      }
      _url {
        url_factsheet
      }
      _feeder_fund {
        main_feeder_fund
      }
      _mydata {
        amc_code
        bloomberg
        citywireselector
        class
        currency
        ft
        investing
        isin
        link
        morningstar_hk
        morningstar_sg
        morningstar_uk
        morningstar_us
        name
        note
        policy
        proj_abbr_name
        proj_id
        source
        source_date
        thai_link
        tradingview
        update_date
      }
      _top5 {
        proj_id
        assetseq
        secur_name
        secur_abbr_name
        period
        secur_invest_size
      }
    }
  }
`;
