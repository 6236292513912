const categoryData = require("../data/categories.json");
const amcData = require("../data/amc.json");

const getCategoryName = (sec_code) => {
  let name = categoryData.find((x) => x.sec_code === sec_code)?.name_en;
  if (name && name.includes("Discretionary")) name = name.slice(0, name.indexOf("Discretionary"));
  if (name && name.includes("Fully")) name = name.slice(0, name.indexOf("Fully"));
  return name;
};

const getAmcName = (unique_id) => {
  const fullname = amcData.find((x) => x.unique_id === unique_id)?.name_en;
  return fullname ? fullname.slice(0, fullname.indexOf("MANAGEMENT")) : null;
};

module.exports = {
  getCategoryName,
  getAmcName,
};
