const capitalize = (text) => {
  if (text) {
    const textarr = text.split(" ");
    let resultarr = [];
    for (const word of textarr) {
      resultarr.push(
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      );
    }
    return resultarr.join(" ");
  } else {
    return null;
  }
};

module.exports = {
  capitalize,
};
